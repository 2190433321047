<template>
    <div class="qr_dispatch_page">
        <div class="qr_dispatch_header">
            <img class="header-logo" src="@/assets/img/logo-icon.svg" alt="Enismaro" style="max-width: 32px;">
            <div style="font-weight: bold;font-size: 19px;" v-if="dispatch">
                {{ dispatch.dispatchID }}
            </div>
        </div>
        <div class="flex items-center" v-if="mode === 'pending-for-approval'">
            <a class="btn btn-primary btn-standart" @click="approveDispatch">
                <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                <span>{{ $translate('approve') }}</span>
            </a>
            <a class="btn btn-w-shadow btn-standart red-color" @click="openRejectDispatchPopup">
                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                <span>{{ $translate('reject') }}</span>
            </a>
        </div>
        <div class="flex items-center" v-else-if="mode === 'creator'">
            <a @click="innerPageMode = 'cancel'" class="btn btn-w-shadow btn-standart red-color">
                <span>{{ $translate('cancel-a-dispatch') }}</span>
            </a>
            <a v-if="canDeliverDispatch" class="btn btn-w-shadow btn-standart" @click="deliverDispatch">
                <inline-svg :src="require(`@/assets/img/transportations.svg`)" />
                <span>{{ $translate('deliver-a-dispatch') }}</span>
            </a>
        </div>
        <div class="flex items-center" v-else-if="mode === 'incoming'">
            <a @click="receiveDispatch" class="btn btn-primary btn-standart">
                <inline-svg :src="require(`@/assets/img/checkmark-circle.svg`)" />
                <span>{{ $translate('receive-dispatch') }}</span>
            </a>
            <a @click="returnDispatch" class="btn btn-w-shadow btn-standart">
                <inline-svg :src="require(`@/assets/img/return-dispatch-icon.svg`)" />
                <span>{{ $translate('return-dispatch') }}</span>
            </a>
        </div>
        <!-- <div class="flex items-center" v-else> -->
            <!-- <a class="btn btn-primary btn-standart">
                <span>Receive Dispatch</span>
            </a>
            <a class="btn btn-w-shadow btn-standart red-color">
                <span>Return Dispatch</span>
            </a> -->
        <!-- </div> -->
        <router-link :to="`/dispatch/${this.$route.params.id}?tab=track-dispatch`" class="btn btn-w-shadow btn-standart">
            <span>{{ $translate('view-dispatch') }}</span>
        </router-link>

        <RejectDispatch
            v-if="rejectDispatchPopup" 
            @close="closeRejectDispatchPopup" 
            @reject="rejectDispatch" 
            @modify="modifyDispatch"
        />
    </div>
</template>

<script>
import { mixDispatches } from "@/mixins/dispatch";
import Popups from "@/components/popups";

export default {
    name: 'QRDispatch',
    components: {
        ...Popups,
    },
    mixins: [mixDispatches],
    data(){
        return {
            rejectDispatchPopup: false,
            innerPageMode: null,
        }
    },
    computed: {
        id(){
            return this.$route.params.id;
        },
        dispatch(){
            return this.$store.state.activeDispatch || {};
        },
        companyData() {
            return this.$store.state.companyData || {};
        },
        mode() {
            if (!this.dispatch?.companiesStatus) return null;
            let status = this.dispatch.companiesStatus.find((c) => c.id == this.companyData.id)?.status || null;
            if(!status){
                return 'view'
            }
            let isCreator = this.isCreator(this.dispatch, this.companyData.id);
            if (isCreator) {
                if (this.innerPageMode=='cancel') {
                    return "cancel";
                }
                if (status=='canceled') {
                    return "canceled";
                }
                return "creator";
            } else {
                if (status == "pending") {
                    return "pending-for-approval";
                }
                if (status == "approved") {
                    return "Approved";
                }
                if (status == "rejected") {
                    return "Rejected";
                }

                if (status == "incoming") {
					if (this.innerPageMode=='receive') {
                    	return "receive";
					}
					if (this.innerPageMode=='return') {
                    	return "return";
					}
                    return "incoming";
                }
                if (status == "completed") {
                    return "Completed";
                }
                if (status == "returned") {
                    return "Returned";
                }
            }
        },
        canDeliverDispatch() {
            if (this.dispatch.companiesStatus && this.dispatch.companiesStatus.length) {
                let isApproved = true;
                this.dispatch.companiesStatus.forEach((c) => {
                    if (c.type == "incoming") {
                        isApproved = isApproved && c.status == "approved";
                    }
                });
                return isApproved;
            }
        },
    },
    methods: {
        async approveDispatch() {
            const options = { title: "Approve Dispatch?", size: "sm", okLabel: "Approve", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    await this.changeCompanyStatus(this.id, this.companyData.id, "approved");
                }
            });
        },
        modifyDispatch() {
            this.$router.push(`/dispatch/modify/${this.id}?fromreject=true`);
        },
        openRejectDispatchPopup() {
            this.rejectDispatchPopup = true;
        },
        closeRejectDispatchPopup() {
            this.rejectDispatchPopup = false;
        },
        async rejectDispatch() {
            await this.changeCompanyStatus(this.id, this.companyData.id, "rejected");
            this.closeRejectDispatchPopup()
        },
        async deliverDispatch() {
            for (let i of this.dispatch.companiesStatus) {
                if (i.type == "outcoming") {
                    await this.changeCompanyStatus(this.dispatch.id, i.id, "outgoing");
                }
                if (i.type == "incoming") {
                    await this.changeCompanyStatus(this.dispatch.id, i.id, "incoming");
                }
            }
            await this.getDispatch(this.id);
        },
        async receiveDispatch() {
            const options = { title: "Receive Dispatch?", size: "sm", okLabel: "Receive", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    await this.changeCompanyStatus(this.id, this.companyData.id, "completed");
                    await this.changeCompanyStatus(this.id, this.companyData.id, "receive");
                }
            });
        },
        async returnDispatch() {
            const options = { title: "Return Dispatch?", size: "sm", okLabel: "Return", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    await this.changeCompanyStatus(this.id, this.companyData.id, "returned");
                }
            });
        },
        checkNullMode(){
            if((this.dispatch.companiesStatus.find((c) => c.id == this.companyData.id) && !this.mode) || !this.dispatch.companiesStatus.find((c) => c.id == this.companyData.id)){
                this.$router.push({path: `/dispatch/${this.$route.params.id}?tab=track-dispatch`})
            }
        }
    },
    async created(){
        this.$store.dispatch('GET_ACTIVE_DISPATCH', { id: this.id });
    },
    mounted(){
        console.log('mode:', this.mode);
        setTimeout(() => {
            console.log('mode:', this.mode);
            this.checkNullMode();
        }, 2000);
    }
}
</script>